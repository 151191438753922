import * as searchParamNames from "../constants/searchParamNames";
import qs from "query-string";

export const OFF_MENU_CATEGORY_ID = "off-menu";

export function menu() {
    return "/";
}

export function category(categoryId: string) {
    return `/category/${categoryId}`;
}

export function offMenu() {
    return category(OFF_MENU_CATEGORY_ID);
}

export function item(
    categoryId: string,
    itemId: string,
    itemCustomizationId?: string,
) {
    const str = `/category/${categoryId}/item/${itemId}`;
    if (itemCustomizationId) {
        return (
            str +
            "?" +
            qs.stringify({
                [searchParamNames.ITEM_CUSTOMIZATION_ID]: itemCustomizationId,
            })
        );
    } else {
        return str;
    }
}

export function combo(
    categoryId: string,
    comboId: string,
    comboCustomizationId?: string,
) {
    const str = `/category/${categoryId}/combo/${comboId}`;
    if (comboCustomizationId) {
        return (
            str +
            "?" +
            qs.stringify({
                [searchParamNames.COMBO_CUSTOMIZATION_ID]: comboCustomizationId,
            })
        );
    } else {
        return str;
    }
}

export function grouping(categoryId: string, groupingId: string) {
    return `/category/${categoryId}/grouping/${groupingId}`;
}

export function managerMenu() {
    return "/manager";
}

export function cancelOrder() {
    return "/manager/cancel";
}

export function voidOrder() {
    return "/manager/void";
}

export function discounts() {
    return "/manager/discounts";
}

export function noSale() {
    return "/manager/no-sale";
}

export function removeTill() {
    return "/manager/remove-till";
}

export function training() {
    return "/manager/training";
}

export function printers() {
    return "/manager/printers";
}

export function previousOrders() {
    return "/manager/previous-orders";
}

export function paidOuts() {
    return "/manager/paid-outs";
}

export function assignManagerCard() {
    return "/manager/assign-card";
}

export function editSettings() {
    return "/manager/settings";
}

export function reports() {
    return "/manager/reports";
}

export function timeReport() {
    return "/manager/reports/time-period";
}

export function balanceReport() {
    return "/manager/reports/balance";
}

export function salesReport() {
    return "/manager/reports/sales-detail";
}

export function checkout() {
    return "/checkout/step-1";
}

export function otherOrders() {
    return "/checkout/other-orders";
}

export function discount() {
    return "/checkout/discount";
}

export function payment(requestingCreditCardPayment?: boolean) {
    const str = "/checkout/step-2";
    return requestingCreditCardPayment
        ? str +
              "?" +
              qs.stringify({
                  [searchParamNames.REQUESTING_CC_PAYMENT]:
                      requestingCreditCardPayment,
              })
        : str;
}

export function giftCardPayment() {
    return "/checkout/gift-card";
}

export function otherPaymentMethods() {
    return "/checkout/other-payment-methods";
}

export function fundraiser() {
    return "/checkout/other-payment-methods/fundraiser";
}

export function ezCater() {
    return "/checkout/other-payment-methods/ez-cater";
}

export function giftCardPurchase() {
    return "/checkout/swipe-gift-card";
}

export function orderComplete() {
    return "/checkout/complete";
}

export function checkoutDriveThru() {
    return "/checkout/drive-thru";
}

export function giftCards() {
    return "/gift-cards";
}

export function giftCardsPurchase(giftCardId?: string) {
    const str = "/gift-cards/purchase";
    return giftCardId
        ? str +
              "?" +
              qs.stringify({
                  [searchParamNames.GIFT_CARD_ID]: giftCardId,
              })
        : str;
}

export function giftCardsBalance() {
    return "/gift-cards/balance";
}

export function giftCardsAddFunds(giftCardId?: string) {
    const str = "/gift-cards/add-funds";
    return giftCardId
        ? str +
              "?" +
              qs.stringify({
                  [searchParamNames.GIFT_CARD_ID]: giftCardId,
              })
        : str;
}

export function giftCardsCashOut() {
    return "/gift-cards/cash-out";
}

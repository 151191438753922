import "normalize.css";
import "./constants/colors.css";
import "./index.css";
import {BrowserRouter} from "react-router-dom";
import {CashierModeProvider} from "./components/CashierModeContext";
import {Provider} from "react-redux";
import {TillAssignmentProvider} from "./components/TillAssignmentContext";
import {Routes, Route, Navigate} from "react-router-dom";
import * as HabitCore from "habit-core";
import * as Sentry from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import App from "./components/App";
import CheckoutGiftCard from "./components/CheckoutGiftCard";
import CheckoutGiftCardPurchase from "./components/CheckoutGiftCardPurchase";
import CheckoutStep1 from "./components/CheckoutStep1";
import CheckoutStep2 from "./components/CheckoutStep2";
import ComboCustomization from "./components/ComboCustomization";
import DeviceSetup from "./components/DeviceSetup";
import Discount from "./components/Discount";
import DriveThroughIdentification from "./components/DriveThroughIdentification";
import GiftCardsAddFunds from "./components/GiftCardsAddFunds";
import GiftCardsBalance from "./components/GiftCardsBalance";
import GiftCardsCashOut from "./components/GiftCardsCashOut";
import GiftCardsHome from "./components/GiftCardsHome";
import GiftCardsPurchase from "./components/GiftCardsPurchase";
import instance from "./lib/api/instance";
import ItemCustomization from "./components/ItemCustomization";
import logger from "./lib/logger";
import ManagerAssignCard from "./components/ManagerAssignCard";
import ManagerBalanceReport from "./components/ManagerBalanceReport";
import ManagerCancel from "./components/ManagerCancel";
import ManagerDiscounts from "./components/ManagerDiscounts";
import ManagerHome from "./components/ManagerHome";
import ManagerMenu from "./components/ManagerMenu";
import ManagerNoSale from "./components/ManagerNoSale";
import ManagerPaidOuts from "./components/ManagerPaidOuts";
import ManagerPreviousOrders from "./components/ManagerPreviousOrders";
import ManagerPrinters from "./components/ManagerPrinters";
import ManagerRemoveTill from "./components/ManagerRemoveTill";
import ManagerReports from "./components/ManagerReports";
import ManagerSalesReport from "./components/ManagerSalesReport";
import ManagerTimeReport from "./components/ManagerTimeReport";
import ManagerSettings from "./components/ManagerSettings";
import ManagerTraining from "./components/ManagerTraining";
import ManagerVoid from "./components/ManagerVoid";
import Menu from "./components/Menu";
import MenuCategoryItems from "./components/MenuCategoryItems";
import OrderComplete from "./components/OrderComplete";
import OrderSubtotalLayout from "./components/OrderSubtotalLayout";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import SecondaryDisplay from "./components/SecondaryDisplay";
import {ParallelModeProvider} from "./components/ParallelModeContext";
import {RefreshProvider} from "./components/RefreshContext";
import GroupingCustomization from "./components/GroupingCustomization";
import Fundraiser from "./components/Fundraiser";
import OtherPaymentMethods from "./components/OtherPaymentMethods";
import EzCater from "./components/EzCater";

let cartExpirationMinutes = import.meta.env.REACT_APP_CART_EXPIRATION_MINUTES
    ? parseInt(import.meta.env.REACT_APP_CART_EXPIRATION_MINUTES)
    : null;

if (cartExpirationMinutes !== null && isNaN(cartExpirationMinutes)) {
    cartExpirationMinutes = null;
}

const versionStr = import.meta.env.DEV
    ? "dev"
    : import.meta.env.REACT_APP_GIT_REVISION;

Sentry.init(
    {
        dsn: import.meta.env.REACT_APP_SENTRY_DSN,
        dist: "1",
        release: versionStr,
        tunnel: import.meta.env.REACT_APP_SENTRY_TUNNEL_URL,
        beforeSend: (event) => {
            if (import.meta.env.DEV) {
                console.log("Intercepted sentry error:", event);
                return null;
            }
            return event;
        },
    },
    SentryReact.init,
);

HabitCore.Config.setConfigData(
    null,
    null,
    cartExpirationMinutes,
    null,
    null, // facebook, not used
    null, // googleAuth, not used
    instance,
    null,
    logger,
    null,
    true,
    versionStr ?? null,
);

const MAX_GC_AMOUNT_CENTS = parseInt(
    import.meta.env.REACT_APP_MAX_GIFT_CARD_VALUE_CENTS ?? "",
);
if (isNaN(MAX_GC_AMOUNT_CENTS)) {
    throw new Error("Invalid max gift card value provided in config");
}

let ready = Promise.resolve();

if (import.meta.env.REACT_APP_MOCK_API === "true") {
    ready = import("./lib/api/apiMock").then((apiMock) => {
        apiMock.setMockResponses(instance);
    });
}

function renderRoutes() {
    return (
        <Routes>
            <Route path="/" element={<App />}>
                <Route path="/" element={<Menu />}>
                    <Route index element={<DeviceSetup />} />
                    <Route
                        path="/category/:categoryId"
                        element={<MenuCategoryItems />}>
                        <Route
                            path="item/:itemId"
                            element={<ItemCustomization />}
                        />
                        <Route
                            path="combo/:comboId"
                            element={<ComboCustomization />}
                        />
                        <Route
                            path="grouping/:groupingId"
                            element={<GroupingCustomization />}
                        />
                    </Route>

                    <Route path="gift-cards" element={<GiftCardsHome />}>
                        <Route path="cash-out" element={<GiftCardsCashOut />} />
                        <Route path="balance" element={<GiftCardsBalance />} />
                        <Route
                            path="add-funds"
                            element={<GiftCardsAddFunds />}
                        />
                        <Route
                            path="purchase"
                            element={<GiftCardsPurchase />}
                        />
                    </Route>
                </Route>

                <Route path="/manager" element={<ManagerMenu />}>
                    <Route path="/manager" element={<ManagerHome />} />

                    <Route path="cancel" element={<ManagerCancel />} />

                    <Route path="void" element={<ManagerVoid />} />

                    <Route path="discounts" element={<ManagerDiscounts />} />

                    <Route path="no-sale" element={<ManagerNoSale />} />

                    <Route path="remove-till" element={<ManagerRemoveTill />} />

                    <Route path="training" element={<ManagerTraining />} />

                    <Route path="printers" element={<ManagerPrinters />} />

                    <Route
                        path="previous-orders"
                        element={<ManagerPreviousOrders />}
                    />

                    <Route path="paid-outs" element={<ManagerPaidOuts />} />
                    <Route path="reports" element={<ManagerReports />}>
                        <Route
                            path="time-period"
                            element={<ManagerTimeReport />}
                        />
                        <Route
                            path="balance"
                            element={<ManagerBalanceReport />}
                        />
                        <Route
                            path="sales-detail"
                            element={<ManagerSalesReport />}
                        />
                    </Route>
                    <Route path="assign-card" element={<ManagerAssignCard />} />
                    <Route path="settings" element={<ManagerSettings />} />
                </Route>

                <Route path="/checkout" element={<OrderSubtotalLayout />}>
                    <Route
                        path="drive-thru"
                        element={<DriveThroughIdentification />}
                    />
                    <Route path="step-1" element={<CheckoutStep1 />} />
                    <Route
                        path="step-2/*"
                        element={<CheckoutStep2 showReturnPrompt={false} />}
                    />
                    <Route path="discount" element={<Discount />} />
                    <Route path="gift-card" element={<CheckoutGiftCard />} />
                    <Route
                        path="other-payment-methods"
                        element={<OtherPaymentMethods />}
                    />
                    <Route
                        path="other-payment-methods/fundraiser"
                        element={<Fundraiser />}
                    />
                    <Route
                        path="other-payment-methods/ez-cater"
                        element={<EzCater />}
                    />
                    <Route
                        path="swipe-gift-card"
                        element={<CheckoutGiftCardPurchase />}
                    />
                    <Route
                        path="other-orders"
                        element={<CheckoutStep2 showReturnPrompt={true} />}
                    />
                    <Route path="complete" element={<OrderComplete />} />
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
        </Routes>
    );
}

ready.then(() => {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <RefreshProvider>
                    <CashierModeProvider>
                        <ParallelModeProvider>
                            <TillAssignmentProvider>
                                <BrowserRouter>{renderRoutes()}</BrowserRouter>
                            </TillAssignmentProvider>
                        </ParallelModeProvider>
                    </CashierModeProvider>
                </RefreshProvider>

                <SecondaryDisplay />
            </Provider>
        </React.StrictMode>,
        document.getElementById("root"),
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as posActions from "../../lib/posActions";
import * as routeHelpers from "../../lib/routeHelpers";
import * as users from "../../lib/api/users";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import OutlineButton from "../OutlineButton";
import PaymentMethodsHeader from "../PaymentMethodsHeader";
import React, {useEffect, useRef, useState} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./EzCater.module.css";
import Spinner from "../Spinner";
import swipeCardIcon from "../../images/swipe-card.svg";
import TextInput from "../TextInput";
import {useExternalInput} from "../../lib/api/useExternalInput";
import {useAppDispatch} from "../../lib/hooks";

const strings = {
    backPromptLabel: "Other Payment Options // EzCater",
    enterNumber: "Enter Manager ID Manually",
    enterNumberInput: "Manager ID",
    instruction:
        "A manager must swipe their card in order to mark this order as paid in EzCater.",
    submit: "Submit",
};

const MANAGER_CARD_LENGTH = 10;

export function EzCater() {
    const dispatch = useAppDispatch();

    const [errorMessage, setErrorMessage] = useState("");
    const [employeeId, setEmployeeId] = useState<string | null>(null);
    const {
        externalInput: cardNumber,
        clearExternalInput: clearCardNumber,
        cardReadError,
    } = useExternalInput(employeeId === null);
    const [submittingCardNumber, setSubmittingCardNumber] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);

    useEffect(() => {
        if (inputRef.current && !keyboardCollapsed) {
            if (document.activeElement === inputRef.current) {
                inputRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    useEffect(() => {
        if (employeeId === "" && inputRef.current) {
            inputRef.current.focus();
            setKeyboardCollapsed(false);
        }
    }, [employeeId]);

    useEffect(() => {
        if (cardNumber.length === MANAGER_CARD_LENGTH) {
            onSubmit();
        }
    }, [cardNumber]);

    function onSubmit() {
        if ((!employeeId && !cardNumber) || submittingCardNumber) {
            return;
        }

        // validate by employee id if card number is not provided
        if (!cardNumber && employeeId) {
            setSubmittingCardNumber(true);
            users
                .validateManagerById(employeeId)
                .then(() => {
                    setEmployeeId(null);
                    dispatch(posActions.applyEzCater());
                })
                .catch((err) => {
                    logger.warn(err);
                    const description = describeNetworkError(err);
                    setErrorMessage(description.join("\n"));
                })
                .finally(() => {
                    setSubmittingCardNumber(false);
                });
        } else {
            setSubmittingCardNumber(true);
            users
                .validateManagerCardNumber(cardNumber)
                .then(() => {
                    dispatch(posActions.applyEzCater());
                    setEmployeeId(null);
                })
                .catch((err) => {
                    logger.warn(err);
                    const description = describeNetworkError(err);
                    setErrorMessage(description.join("\n"));
                })
                .finally(() => {
                    setSubmittingCardNumber(false);
                    clearCardNumber();
                });
        }
    }

    function onSubmitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        onSubmit();
    }

    function getNonManualContent() {
        return (
            <>
                {!submittingCardNumber ? (
                    <>
                        <OutlineButton
                            className={styles.enterNumberButton}
                            label={strings.enterNumber}
                            onClick={() => {
                                setEmployeeId("");
                            }}
                            type="button"
                        />
                        {errorMessage ? (
                            <div className={styles.pinError}>
                                {errorMessage}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div className={styles.cardSwipeLoadingIndicator}>
                        <Spinner />
                    </div>
                )}
            </>
        );
    }

    function getManualContent() {
        if (employeeId === null) {
            return null;
        }

        return (
            <div className={styles.inputContainer}>
                <TextInput
                    ref={inputRef}
                    className={styles.pinInput}
                    value={employeeId}
                    onChange={(e) => {
                        setEmployeeId(e.target.value);
                        setErrorMessage("");
                    }}
                    onFocus={() => setKeyboardCollapsed(false)}
                    placeholder={strings.enterNumberInput}
                    required={true}
                    inputMode="none"
                />
                {errorMessage ? (
                    <div className={styles.pinError}>{errorMessage}</div>
                ) : null}
                <OutlineButton
                    className={styles.submitButton}
                    label={strings.submit}
                    type="submit"
                    disabled={!employeeId}
                    loading={submittingCardNumber}
                />
            </div>
        );
    }

    return (
        <>
            <div className={styles.container}>
                <PaymentMethodsHeader
                    backPromptLabel={strings.backPromptLabel}
                    to={routeHelpers.otherPaymentMethods()}
                />

                <form
                    className={styles.contentContainer}
                    onSubmit={onSubmitForm}>
                    <img className={styles.icon} src={swipeCardIcon} alt="" />
                    <div className={styles.instructions}>
                        {strings.instruction}
                    </div>
                    {employeeId === null
                        ? getNonManualContent()
                        : getManualContent()}
                </form>

                <div
                    className={cn(
                        styles.keyboardContainer,
                        keyboardCollapsed && styles.keyboardContainerCollapsed,
                    )}>
                    <StyledKeyboard
                        currentInput={employeeId ?? ""}
                        visible={!keyboardCollapsed}
                        setVisible={(val: boolean) =>
                            setKeyboardCollapsed(!val)
                        }
                        onChange={(input) => setEmployeeId(input)}
                        onPressEnter={onSubmit}
                        inputRefs={[inputRef]}
                    />
                </div>
            </div>
            {cardReadError ? (
                <ErrorModal
                    title={cardReadError.title}
                    errorMessage={cardReadError.message}
                    backgroundColor="grey"
                    onClose={() => clearCardNumber()}
                />
            ) : null}
        </>
    );
}
